<template>
  <b-container>
    <b-row class="mt-4">
      <b-col>
        <b-btn
          variant="success"
          @click="confirmMatches"
        >
          Confirm matches
        </b-btn>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <label for="">Project</label>
        <FormTags
          v-model="project"
          name="project"
          :limit="1"
          button-text="Select project"
          :options="projects"
        >
          <template #item="{option}">
            <b>{{ option.business.name }}</b> - {{ option.title }}
          </template>
        </FormTags>
      </b-col>
    </b-row>
    Role
    <b-form-select
      v-model="selectedRole"
      :options="roleOptions"
    />
    Industry
    <b-form-select
      v-model="selectedIndustry"
      :options="industryOptions"
    />
    Regions
    <b-form-select
      v-model="selectedRegion"
      :options="regionOptions"
    />
    Country
    <b-form-input
      v-model="country"
    />
    Country expertise
    <b-form-select
      v-model="countryExpertise"
      :options="countryOptions"
    />
    <b-row>
      <b-col>
        <label for="">Exclude country</label>
        <FormTags
          v-model="excludeCountry"
          name="exclude_country"
          button-text="Select country"
          :options="countryOptions"
        >
          <template #item="{option}">
            <b>{{ option.text }}</b>
          </template>
        </FormTags>
      </b-col>
    </b-row>
    Languages
    <b-form-select
      v-model="languages"
      :options="languageOptions"
    />
    <b-row>
      <b-col>
        <label for="">Agency status</label>
        <FormTags
          v-model="status"
          name="agency_status"
          button-text="Select status"
          :options="statuses"
        >
          <template #item="{option}">
            <b>{{ option.text }}</b>
          </template>
        </FormTags>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <label for="">Exclude Agency status</label>
        <FormTags
          v-model="excludeAgencyStatus"
          name="exclude_status"
          button-text="Select status"
          :options="statuses"
        >
          <template #item="{option}">
            <b>{{ option.text }}</b>
          </template>
        </FormTags>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <label for="">Agency availability</label>
        <FormTags
          v-model="availability"
          name="availability"
          button-text="Select availability"
          :options="availabilityOptions"
        >
          <template #item="{option}">
            <b>{{ option.text }}</b>
          </template>
        </FormTags>
      </b-col>
    </b-row>
    <span class="ml-2 mt-2">
      <b>Counter:</b> {{ filteredAgencies.length }}
    </span>
    <b-form-checkbox-group v-model="selectedAgencies">
      <b-card
        v-for="agency in filteredAgencies"
        :key="agency.id"
        class="mb-4"
      >
        <b-form-checkbox :value="agency.id">
          Select for match
        </b-form-checkbox>

        <AdminAgencyProfileCard
          :match="{
            agency,
            project: {}
          }"
        />
        <div>
          <b-btn
            class="mr-5"
            variant="success"
            :to="`/admin/agency/${agency.id}/edit`"
          >
            Edit
          </b-btn>
          <span class="ml-2">
            <b-btn
              class="mr-2"
              @click="vetting(agency)"
            >
              Vetting
            </b-btn>
            <b>Ag. status:</b>
            <b-form-select
              v-model="agency.status"
              :options="statuses"
              size="sm"
              style="width: fit-content;"
            >
              <template #first>
                <b-form-select-option :value="agency.status">
                  {{ agency.status }}
                </b-form-select-option>
              </template>
            </b-form-select>
            |
            <b>Agency availability:</b> {{ agency.availability }}
            <b-btn
              class="ml-1 p-0"
              variant="admin-primary"
              @click="saveAgencyInfo(agency)"
            >
              Save
            </b-btn>
          </span>
          <b-btn
            variant="danger"
            class="float-right"
            @click="remove(agency.id)"
          >
            Delete
          </b-btn>
          <p>{{ (agency.users[0] || {}).email }}</p>
        </div>
      </b-card>
    </b-form-checkbox-group>
  </b-container>
</template>

<script>
import axios from '@/helpers/axios'
import AdminAgencyProfileCard from '@/modules/agency/components/AdminAgencyProfileCard'
import FormTags from '@/components/FormTags'
import { AGENCY_STATUSES, statusToArray } from '@/helpers/statuses'

export default {
  name: 'AdminAgenciesList',
  components: {
    AdminAgencyProfileCard,
    FormTags,
  },
  data () {
    return {
      agencies: [],
      selectedAgencies: [],
      project: null,
      projects: [],
      // Filters
      roleOptions: [],
      industryOptions: [],
      selectedRole: null,
      country: null,
      regionOptions: [],
      selectedRegion: null,
      selectedIndustry: null,
      languages: null,
      languageOptions: [],
      status: [],
      excludeAgencyStatus: [],
      availability: [],
      countryExpertise: [],
      excludeCountry: [],
      statuses: statusToArray(AGENCY_STATUSES),
      availabilityOptions: [
        { text: 'Not available', value: 'Not available' },
        { text: 'Available', value: 'Available' },
        { text: 'Available from January', value: 'Available from January' },
        { text: 'Available from February', value: 'Available from February' },
        { text: 'Available from March', value: 'Available from March' },
        { text: 'Available from April', value: 'Available from April' },
        { text: 'Available from May', value: 'Available from May' },
        { text: 'Available from June', value: 'Available from June' },
        { text: 'Available from July', value: 'Available from July' },
        { text: 'Available from August', value: 'Available from August' },
        { text: 'Available from September', value: 'Available from September' },
        { text: 'Available from October', value: 'Available from October' },
        { text: 'Available from November', value: 'Available from November' },
        { text: 'Available from December', value: 'Available from December' },
      ],
      countryOptions: [],
      availabilities: [
        'Available',
        'Not available',
        'Available from January',
        'Available from February',
        'Available from March',
        'Available from April',
        'Available from May',
        'Available from June',
        'Available from July',
        'Available from August',
        'Available from September',
        'Available from October',
        'Available from November',
        'Available from December',
      ],
    }
  },
  computed: {
    filteredAgencies () {
      let agencies = this.agencies
      const role = (this.selectedRole || '').toLowerCase()
      const industry = (this.selectedIndustry || '').toLowerCase()
      const country = (this.country || '').toLowerCase()
      const countryExpertise = this.countryExpertise
      const region = (this.selectedRegion || '').toLowerCase()
      const languages = this.languages
      const status = this.status.map(status => (status || '').toLowerCase())
      const availability = this.availability.map(availability => (availability || '').toLowerCase())
      const excludeCountry = this.excludeCountry
      const excludeAgencyStatus = this.excludeAgencyStatus

      // Apply selectedRole filter
      if (role) {
        agencies = agencies.filter(r => r.roles.find(r => r.id === role))
      }
      // Apply selectedIndustry filter
      if (industry) {
        agencies = agencies.filter(r => r.industries.find(r => r.id === industry))
      }
      // Start of country fiter
      if (country) {
        agencies = agencies.filter(r => ((r.country || {}).label || '').toLowerCase().includes(country))
      } else if (excludeCountry.length) {
        agencies = agencies.filter(r => !excludeCountry.includes((r.country || {}).id))
      }
      if (countryExpertise.length) {
        agencies = agencies.filter(r => r.local_insights.map(i => i.id).includes(countryExpertise))
      }
      // End of country filter
      if (region) {
        agencies = agencies.filter(r => r.regions.find(r => r.id === region))
      }
      if (languages) {
        agencies = agencies.filter(r => r.languages.map(i => i.id).includes(languages))
      }
      if (status.length) {
        agencies = agencies.filter(r => status.includes((r.status || '').toLowerCase()))
      }
      if (excludeAgencyStatus.length) {
        agencies = agencies.filter(r => !excludeAgencyStatus.includes(r.status))
      }
      if (availability.length) {
        agencies = agencies.filter(r => availability.includes((r.availability || '').toLowerCase()))
      }
      return agencies
    },
  },
  created () {
    this.getAgencies()
    axios.get('/public/v2/values/roles')
      .then(({ data }) => {
        this.roleOptions = this.mapValues(data, true)
      })
    axios.get('/public/v2/values/industries')
      .then(({ data }) => {
        this.industryOptions = this.mapValues(data, true)
      })
    axios.get('/public/v2/values/regions')
      .then(({ data }) => {
        this.regionOptions = this.mapValues(data, true)
      })
    axios.get('/public/v2/values/countries')
      .then(({ data }) => {
        let temp = this.mapValues(data)
        temp = temp.sort((a, b) => (a.label > b.label) ? 1 : -1)
        this.countryOptions = temp
      })
    axios.get('/admin/v2/projects')
      .then(({ data }) => {
        this.projects = this.mapValues(data, false, 'title')
      })
    axios.get('/public/v2/values/languages')
      .then(({ data }) => {
        let temp = this.mapValues(data)
        temp = temp.sort((a, b) => (a.label > b.label) ? 1 : -1)
        this.languageOptions = temp
      })
  },
  methods: {
    mapValues (data, prepend = false, textField = 'label') {
      let dataArr = data.map(option => {
        option.value = option.id
        option.text = option[textField]
        return option
      })
      if (prepend) {
        dataArr = [
          { text: 'Select One', value: null },
          ...dataArr,
        ]
      }
      return dataArr
    },
    getAgencies () {
      return axios.get('/admin/v2/agencies')
        .then(({ data }) => {
          this.agencies = data
        })
    },
    remove (agencyId) {
      const confirmed = confirm('U are about to delete a agency, sure you want to do it?')
      if (confirmed) {
        axios.delete(`/admin/v2/agencies/${agencyId}`)
          .then(() => {
            this.getAgencies()
              .then(() => alert('Deleted'))
          })
      }
    },
    confirmMatches () {
      axios.post('/admin/v2/matches/create', {
        project: this.project[0],
        agencies: this.selectedAgencies,
      })
        .then(({ data }) => {
          alert('Matches made!')
        })
        .catch(() => {
          alert('Match already exists or some other error!')
        })
    },
    vetting (agency) {
      agency.verified = !agency.verified
      axios.patch(`/admin/v2/agencies/${agency.id}`, {
        verified: agency.verified,
      }).then(() => {
        this.getAgencies()
      })
    },
    saveAgencyInfo (agency) {
      axios.patch(`/admin/v2/agencies/${agency.id}`, {
        status: agency.status,
        availability: agency.availability,
      })
    },
  },
}
</script>

<style>

</style>
