var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-container',[_c('b-row',{staticClass:"mt-4"},[_c('b-col',[_c('b-btn',{attrs:{"variant":"success"},on:{"click":_vm.confirmMatches}},[_vm._v(" Confirm matches ")])],1)],1),_c('b-row',[_c('b-col',[_c('label',{attrs:{"for":""}},[_vm._v("Project")]),_c('FormTags',{attrs:{"name":"project","limit":1,"button-text":"Select project","options":_vm.projects},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var option = ref.option;
return [_c('b',[_vm._v(_vm._s(option.business.name))]),_vm._v(" - "+_vm._s(option.title)+" ")]}}]),model:{value:(_vm.project),callback:function ($$v) {_vm.project=$$v},expression:"project"}})],1)],1),_vm._v(" Role "),_c('b-form-select',{attrs:{"options":_vm.roleOptions},model:{value:(_vm.selectedRole),callback:function ($$v) {_vm.selectedRole=$$v},expression:"selectedRole"}}),_vm._v(" Industry "),_c('b-form-select',{attrs:{"options":_vm.industryOptions},model:{value:(_vm.selectedIndustry),callback:function ($$v) {_vm.selectedIndustry=$$v},expression:"selectedIndustry"}}),_vm._v(" Regions "),_c('b-form-select',{attrs:{"options":_vm.regionOptions},model:{value:(_vm.selectedRegion),callback:function ($$v) {_vm.selectedRegion=$$v},expression:"selectedRegion"}}),_vm._v(" Country "),_c('b-form-input',{model:{value:(_vm.country),callback:function ($$v) {_vm.country=$$v},expression:"country"}}),_vm._v(" Country expertise "),_c('b-form-select',{attrs:{"options":_vm.countryOptions},model:{value:(_vm.countryExpertise),callback:function ($$v) {_vm.countryExpertise=$$v},expression:"countryExpertise"}}),_c('b-row',[_c('b-col',[_c('label',{attrs:{"for":""}},[_vm._v("Exclude country")]),_c('FormTags',{attrs:{"name":"exclude_country","button-text":"Select country","options":_vm.countryOptions},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var option = ref.option;
return [_c('b',[_vm._v(_vm._s(option.text))])]}}]),model:{value:(_vm.excludeCountry),callback:function ($$v) {_vm.excludeCountry=$$v},expression:"excludeCountry"}})],1)],1),_vm._v(" Languages "),_c('b-form-select',{attrs:{"options":_vm.languageOptions},model:{value:(_vm.languages),callback:function ($$v) {_vm.languages=$$v},expression:"languages"}}),_c('b-row',[_c('b-col',[_c('label',{attrs:{"for":""}},[_vm._v("Agency status")]),_c('FormTags',{attrs:{"name":"agency_status","button-text":"Select status","options":_vm.statuses},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var option = ref.option;
return [_c('b',[_vm._v(_vm._s(option.text))])]}}]),model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}})],1)],1),_c('b-row',[_c('b-col',[_c('label',{attrs:{"for":""}},[_vm._v("Exclude Agency status")]),_c('FormTags',{attrs:{"name":"exclude_status","button-text":"Select status","options":_vm.statuses},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var option = ref.option;
return [_c('b',[_vm._v(_vm._s(option.text))])]}}]),model:{value:(_vm.excludeAgencyStatus),callback:function ($$v) {_vm.excludeAgencyStatus=$$v},expression:"excludeAgencyStatus"}})],1)],1),_c('b-row',[_c('b-col',[_c('label',{attrs:{"for":""}},[_vm._v("Agency availability")]),_c('FormTags',{attrs:{"name":"availability","button-text":"Select availability","options":_vm.availabilityOptions},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var option = ref.option;
return [_c('b',[_vm._v(_vm._s(option.text))])]}}]),model:{value:(_vm.availability),callback:function ($$v) {_vm.availability=$$v},expression:"availability"}})],1)],1),_c('span',{staticClass:"ml-2 mt-2"},[_c('b',[_vm._v("Counter:")]),_vm._v(" "+_vm._s(_vm.filteredAgencies.length)+" ")]),_c('b-form-checkbox-group',{model:{value:(_vm.selectedAgencies),callback:function ($$v) {_vm.selectedAgencies=$$v},expression:"selectedAgencies"}},_vm._l((_vm.filteredAgencies),function(agency){return _c('b-card',{key:agency.id,staticClass:"mb-4"},[_c('b-form-checkbox',{attrs:{"value":agency.id}},[_vm._v(" Select for match ")]),_c('AdminAgencyProfileCard',{attrs:{"match":{
          agency: agency,
          project: {}
        }}}),_c('div',[_c('b-btn',{staticClass:"mr-5",attrs:{"variant":"success","to":("/admin/agency/" + (agency.id) + "/edit")}},[_vm._v(" Edit ")]),_c('span',{staticClass:"ml-2"},[_c('b-btn',{staticClass:"mr-2",on:{"click":function($event){return _vm.vetting(agency)}}},[_vm._v(" Vetting ")]),_c('b',[_vm._v("Ag. status:")]),_c('b-form-select',{staticStyle:{"width":"fit-content"},attrs:{"options":_vm.statuses,"size":"sm"},scopedSlots:_vm._u([{key:"first",fn:function(){return [_c('b-form-select-option',{attrs:{"value":agency.status}},[_vm._v(" "+_vm._s(agency.status)+" ")])]},proxy:true}],null,true),model:{value:(agency.status),callback:function ($$v) {_vm.$set(agency, "status", $$v)},expression:"agency.status"}}),_vm._v(" | "),_c('b',[_vm._v("Agency availability:")]),_vm._v(" "+_vm._s(agency.availability)+" "),_c('b-btn',{staticClass:"ml-1 p-0",attrs:{"variant":"admin-primary"},on:{"click":function($event){return _vm.saveAgencyInfo(agency)}}},[_vm._v(" Save ")])],1),_c('b-btn',{staticClass:"float-right",attrs:{"variant":"danger"},on:{"click":function($event){return _vm.remove(agency.id)}}},[_vm._v(" Delete ")]),_c('p',[_vm._v(_vm._s((agency.users[0] || {}).email))])],1)],1)}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }